<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="填报报表配置"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="view"
        >
          <template #customBtn="{ row, index }">
            <el-button
              size="small"
              type="text"
              @click.stop="rowEdit(row)"
            >
              配置
            </el-button>
            <el-button
              size="small"
              type="text"
              @click.stop="rowView(row)"
            >
              预览
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <reportAllocationDialog
      ref="reportAllocation"
      v-if="isReportAllocationDialog"
      :formData="formData"
      @closeDialog="closeDialog"
    >
    </reportAllocationDialog>
    <el-dialog
      :title="this.type == 'edit' ? '境外项目人员信息编辑' : (this.type == 'view' ? '境外项目人员信息查看' : '境外项目人员信息新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="overseasProjectFilling"
      width="70%"
      top="5vh"
      @close="overseasDialog"
    >
      <overseasProjectFillingEdit ref="overseasProjectFillingEdit" :rowData="rowData" v-if="overseasProjectFilling"
                                  @overseasDialog="overseasDialog"></overseasProjectFillingEdit>
    </el-dialog>
    <el-dialog
      :title="this.type == 'edit' ? '境内项目人员信息编辑' : (this.type == 'view' ? '境内项目人员信息查看' : '境内项目人员信息新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="isFormDialog"
      width="70%"
      top="5vh"
      @close="domesticDialog"
    >
      <formDialog ref="overseasProjectFillingEdit" :formData="formData" v-if="isFormDialog"
                  @domesticDialog="domesticDialog"></formDialog>
    </el-dialog>

    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '安全生产月报报表编辑' : (this.safeType == 'view' ? '安全生产月报报表查看' : '安全生产月报报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      v-if="showDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="closeDialog"
    >
      <safetyDialog ref="safetyDialog" :rowQuery="rowQuery" @closeDialog="closeDialog"></safetyDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '生态环保月报报表编辑' : (this.safeType == 'view' ? '生态环保月报报表查看' : '生态环保月报报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="ecologicalShowDialog"
      v-if="ecologicalShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="closeDialog"
    >
      <ecologicalDialog ref="ecologicalDialog" :rowQuery="rowQuery" @closeDialog="closeDialog"></ecologicalDialog>
    </el-dialog>

    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '安全生产事故情况报表编辑' : (this.safeType == 'view' ? '安全生产事故情况报表查看' : '安全生产事故情况报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="safeShowDialog"
      v-if="safeShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="closeDialog"
    >
      <safetyAccidentsDialog ref="safetyAccidentsDialog" :rowQuery="rowQuery" @closeDialog="closeDialog"></safetyAccidentsDialog>
    </el-dialog>

    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '政府部门生态环保检查及处罚情况编辑' : (this.safeType == 'view' ? '政府部门生态环保检查及处罚情况查看' : '政府部门生态环保检查及处罚情况新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="governmentShowDialog"
      v-if="governmentShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      @close="closeDialog"
    >
      <governmentDepartmentDialog ref="governmentDepartmentDialog" :rowQuery="rowQuery"
                                  @closeDialog="closeDialog"></governmentDepartmentDialog>
    </el-dialog>

    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '政府部门安全生产检查及处罚情况编辑' : (this.safeType == 'view' ? '政府部门安全生产检查及处罚情况查看' : '政府部门安全生产检查及处罚情况新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="securityShowDialog"
      v-if="securityShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      @close="closeDialog"
    >
      <protectionDialog ref="protectionDialog" :rowQuery="rowQuery" @closeDialog="closeDialog"></protectionDialog>
    </el-dialog>

    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '安全活动统计报表编辑' : (this.safeType == 'view' ? '安全活动统计报表查看' : '安全活动统计报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="safetActionShowDialog"
      v-if="safetActionShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="closeDialog"
    >
      <safetActionStatDialog ref="safetActionStatDialog" :rowQuery="rowQuery" @closeDialog="closeDialog"></safetActionStatDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '隐患排查治理统计编辑' : (this.safeType == 'view' ? '隐患排查治理统计查看' : '隐患排查治理统计新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="hazardShowDialog"
      v-if="hazardShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      @close="closeDialog"
    >
      <hazardInvestigationDialog ref="hazardInvestigationDialog" :rowQuery="rowQuery"
                                 @closeDialog="closeDialog"></hazardInvestigationDialog>
    </el-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonDialog from "@/components/CommonDialog";
import {attributeList} from "@/api/reportFixationTasks";
import reportAllocationDialog
  from "@/views/business/reportManager/fillReportAllocation/components/reportAllocationDialog.vue"
import overseasProjectFillingEdit from "@/views/business/reportManager/fillReport/overseasProjectFilling/edit.vue";
import formDialog from "@/views/business/reportManager/fillReport/domesticProjectFilling/formDialog.vue";
import safetyDialog from "@/views/business/reportManager/fillReport/safetyProductionMonthly/safetyDialog.vue";
import ecologicalDialog
  from "@/views/business/reportManager/fillReport/ecologicalProductionMonthly/ecologicalDialog.vue";
import governmentDepartmentDialog
  from "@/views/business/reportManager/fillReport/governmentDepartmentSafetyProductionFilling/governmentDepartmentDialog.vue";
import protectionDialog
  from "@/views/business/reportManager/fillReport/securityProtectionSafetyFilling/protectionDialog.vue";
import hazardInvestigationDialog
  from "@/views/business/reportManager/fillReport/hazardInvestigationFilling/hazardInvestigationDialog.vue";
import safetActionStatDialog from "@/views/business/reportManager/fillReport/safetActionStat/safetyDialog";
import safetyAccidentsDialog from "@/views/business/reportManager/fillReport/safeAccidentStatFilling/safetyDialog";
export default {
  components: {
    hazardInvestigationDialog,
    protectionDialog,
    governmentDepartmentDialog,
    ecologicalDialog,
    safetyDialog,
    safetActionStatDialog,
    safetyAccidentsDialog,
    formDialog,
    overseasProjectFillingEdit,
    HeadLayout,
    GridLayout,
    CommonDialog,
    reportAllocationDialog
  },
  data() {
    return {
      // dicData: [],
      isReportAllocationDialog: false,
      formData: {},
      bpmnOption: {},
      circulationVisible: false,
      bpmnVisible: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      tableLoading: false,
      //境外
      overseasProjectFilling: false,
      rowData: {},
      //境内
      type:"view",
      isFormDialog:false,
      //安全月报
      safeType:"view",
      fullscreen: false,
      rowQuery: {},
      showDialog: false,
      ecologicalShowDialog:false,
      safeShowDialog:false,
      governmentShowDialog:false,
      securityShowDialog:false,
      safetActionShowDialog:false,
      hazardShowDialog:false,
    }
  },
  computed: {
    searchColumns() {
      return [
        {
          placeholder: "请输入报表名称",
          prop: "title",
          span: 4,
        },
        // {
        //   placeholder: "请选择采集周期",
        //   prop: "acquisitionCycleType",
        //   type: "select",
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=fill_report_allocation_type",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   span: 4,
        // },
        // {
        //   placeholder: "请选择业务分类",
        //   prop: "reportCategoryCode",
        //   type: "select",
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=report_category",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   span: 4,
        // },
      ]
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        selection: false,
        linklabel:"title",
        column: [
          {
            label: "报表名称",
            prop: "title",
            align: 'left',
            overHidden: true,
          },
          {
            label: "业务分类",
            prop: "reportCategoryCode",
            align: 'center',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=report_category",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "采集周期",
            prop: "acquisitionCycleType",
            align: 'center',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=fill_report_allocation_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
        ]
      }
    }
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.ecologicalShowDialog = false;
      this.isReportAllocationDialog = false
      this.safeShowDialog = false
      this.governmentShowDialog = false
      this.securityShowDialog = false
      this.safetActionShowDialog = false
      this.hazardShowDialog = false
      this.onLoad(this.page)
    },
    listener(flag) {
      this.isReportAllocationDialog = flag;
      this.formData = []
      this.$nextTick(() => {
        this.$refs.reportAllocation.reportConfigurationDialog = flag;
      })
    },
    rowEdit(row) {
      this.isReportAllocationDialog = true;
      this.formData = row
      this.$nextTick(() => {
        this.$refs.reportAllocation.reportConfigurationDialog = true;
      })
    },
    view(row){
        this.$router.push({
          path:'/business/reportManager/reportDistribution/index',
          query:{
            templateCode: row.templateCode,
          }
        })
    },
    rowView(row){
      if("overseasCode" == row.templateCode){
        this.overseasProjectFilling = true
        this.type = "view";
        this.rowData = {
          type: 'view',
        };
      }else if ("domesticCode" == row.templateCode){
        this.isFormDialog = true;
        this.formData = {
          type: 'view',
        }
      }else if ("safetyProductionMonthly" == row.templateCode){
        this.safeType = "view"
        this.showDialog = true
        this.rowQuery = {
          type: 'view',
        }
      }else if ("ecologicalProductionMonthly" == row.templateCode){
        this.ecologicalShowDialog = true
        this.rowQuery = {
          type: 'view',
        }
        this.safeType = "view"

      }else if ("safetActionStat" == row.templateCode){
        this.safetActionShowDialog = true
        this.rowQuery = {
          type: 'view',
        }
        this.safeType = "view"
      }else if ("rptAdminPunishStat" == row.templateCode){
        this.securityShowDialog = true
        this.rowQuery = {
          type: 'view',
        }
        this.safeType = "view"
      }else if ("governmentDepartmentSafetyProduction" == row.templateCode){
        this.governmentShowDialog = true
        this.rowQuery = {
          type: 'view',
        }
        this.safeType = "view"
      }else if ("safeAccidentStat" == row.templateCode){
        this.safeShowDialog = true
        this.rowQuery = {
          type: 'view',
        }
        this.safeType = "view"
      }else if ("hazardInvestigation" == row.templateCode){
        this.hazardShowDialog = true
        this.rowQuery = {
          type: 'view',
        }
        this.safeType = "view"
      }
    },
    overseasDialog() {
      this.overseasProjectFilling = false;
      this.onLoad(this.page)
    },
    domesticDialog() {
      this.isFormDialog = false;
      this.onLoad(this.page)
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      attributeList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.tableData = data.records;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableLoading = false;
      });
    },
  },
  created() {
    this.onLoad(this.page);
  }
}
</script>

<style scoped>

</style>
